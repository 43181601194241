
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {}
})
export default class NaviBar extends Vue {
  @Prop() isNeedConfirm = false
  @Prop() title = ''

  back () {
    if (this.isNeedConfirm) {
      this.$emit('back')
    } else {
      console.log('go -1')
      this.$router.go(-1)
    }
  }
}
